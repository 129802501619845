import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch, useAppSelector } from 'state'
import styled from 'styled-components'
import { Flex, useMatchBreakpoints } from 'pancake-uikit'
import { Avatar, Button, DatePicker, DatePickerProps, Input, Slider, message } from 'antd'
import { fetchStakingData, fetchStakingUserData, setRef, setStakingData, setStakingUserData } from 'state/staking'
import { ethers, utils } from 'ethers'
import { format } from 'date-fns'
import Dots from 'components/Loader/Dots'
import {
  fetchCatStakingData,
  fetchCatStakingUserData,
  setCatStakingData,
  setCatStakingUserData,
} from 'state/catStaking'
import { getCatStakingAddress, getStakingAddress } from 'utils/addressHelpers'
import { CurrencyAmount, WETH } from 'pancake-sdk'
import useI18nText from 'hooks/useI18n'
import { useAddStage, useDeposit, useWithdraw, useWithdraw2, usetCatmeApprove, usetUsdtApprove } from './hook'

const HomeBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  .flex-top {
    @media screen and (min-width: 360px) {
      height: 50px;
      width: 100%;
    }
    @media screen and (min-width: 576px) {
      height: 0px;
      width: 100%;
    }
  }
  .flex {
    height: 155px;
    width: 100%;
    /* display: flex; */

    display: flex;
    @media screen and (min-width: 360px) {
      position: none;
      bottom: 20px;
      height: 40px;

      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      position: fixed;
      bottom: 0px;
      height: 155px;
      margin-top: 0px;
    }
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-left: 10px;
      @media screen and (min-width: 360px) {
        width: 96px;
        /* height: 100vh; */
      }
      @media screen and (min-width: 576px) {
        width: 150px;
      }
    }
  }
  .body {
    height: 470px;
    border-radius: 20px;
    /* background-color: #fff; */
    > div:nth-child(1) {
      background-color: #fff;
      border-radius: 20px;
      padding: 10px 0px 40px 0px;
    }
    margin-top: 40px;

    @media screen and (min-width: 360px) {
      width: 100%;
      padding: 0px 0px;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }

    .title {
      p {
        color: rgb(75 85 99 / var(1));
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        margin: 20px 0px 5px 0px;
      }
      span {
        font-size: 1rem;
        line-height: 1;
        color: rgb(31 41 55 / 1);
        font-weight: 600;
      }
    }

    .data {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      > div {
        padding: 0px 10px;
      }
      p {
        color: rgb(107 114 128);
        font-weight: 600;
        font-size: 0.85rem;
        line-height: 1rem;
        margin: 10px 0px 5px 0px;
      }
      span {
        font-size: 0.75rem;
        line-height: 1;
        color: rgb(107 114 128);
        font-weight: 600;
      }
    }

    .input {
      border-radius: 5px;
      padding: 10px;
      border: 1px solid #e5e7eb;
      margin: 20px 20px 0px 20px;
      .div1 {
        div {
          color: rgb(148 163 184);
          font-size: 12px;
          line-height: 1.25rem;
        }
      }
    }

    .button {
      margin: 40px 20px 0px 20px;

      .ant-slider-track {
        background-color: rgb(255, 218, 217);
      }
      .ant-slider-handle::after {
        box-shadow: 0 0 0 2px #e7b7ab;
      }

      .ant-btn {
        background: '#fff';
      }
    }
  }

  .zhu {
    padding: 20px;
    @media screen and (min-width: 360px) {
      width: 100%;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }
    font-size: 15px;
  }
  .ref {
    border-radius: 20px;
    background-color: #fff;
    margin-top: 40px;
    margin-top: 20px;
    padding: 20px;
    @media screen and (min-width: 360px) {
      width: 100%;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1;
      color: rgb(107 114 128);
      font-weight: 600;
      margin: 5px 0px;
      text-align: left;
    }
    h1 {
      text-align: left;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
`
const Max = styled.div``

const LockInput = styled(Input)`
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  > input {
    background-color: #f3f3f3;
    &:focus,
    &:focus-within,
    &:hover {
      background-color: #f3f3f3;
    }

    &:focus {
      box-shadow: none;
    }
  }
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #f3f3f3;
  }

  &:focus {
    box-shadow: none;
  }
`

const LockButton = styled(Button)`
  border: 1px solid #ffdad9;
  /* background-color: '#ffdad9' !important; */
  background-color: rgb(231, 183, 171);
  &:hover {
    color: #d39c63 !important;
    border: 1px solid #e7b7ab !important;
  }
`

/* eslint-disable */
function getFirstTenCharacters(str) {
  if (str.length >= 10) {
    return str.substring(0, 10)
  } else {
    return '0.0'
  }
}
const { RangePicker } = DatePicker

const LockDatePicker = styled(DatePicker)`
  background: #f3f3f3;
  height: 48px;
  width: 100%;
  border-radius: 5px;
  border: none;
  border: 1px solid #f3f3f3;
  margin-bottom: 20px;
  padding: 4px 10px;
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #f3f3f3;
    border: 1px solid #ffbb00;
    box-shadow: none;
  }
`

const AdminBody = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 16px;
  margin-top: 20px;
`
const Admin = () => {
  const store = useAppSelector((state) => state.catStaking)
  const { chainId, account } = useWeb3React()
  console.log(store)
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [inputAmount, setInputAmount] = useState('')

  const onOk = useCallback((value: DatePickerProps['value'] | any) => {
    console.log(value)
  }, [])

  const onChange1 = useCallback(
    (value: any, dateString: any) => {
      console.log(new Date(dateString as string).valueOf())
      const newLockDate = new Date(dateString as string).valueOf() / 1000
      setStartTime(newLockDate)
    },
    [setStartTime]
  )

  const onChange2 = useCallback(
    (value: any, dateString: any) => {
      console.log(new Date(dateString as string).valueOf())
      const newLockDate = new Date(dateString as string).valueOf() / 1000
      setEndTime(newLockDate)
    },
    [setStartTime]
  )

  console.log(inputAmount)

  const { isLoading: isApproveLoading, approve } = usetUsdtApprove(getCatStakingAddress(chainId))

  const { isLoading: isWithdrawLoading, withdraw } = useAddStage({ startTime, endTime, inputAmount })
  return (
    <AdminBody>
      <Flex>
        <LockDatePicker popupClassName="adsdas" showTime={{ format: 'HH:mm' }} onChange={onChange1} onOk={onOk} />
        <LockDatePicker popupClassName="adsdas" showTime={{ format: 'HH:mm' }} onChange={onChange2} onOk={onOk} />
      </Flex>
      <Flex>
        <Input value={inputAmount} onChange={(e) => setInputAmount(e?.target?.value)} style={{ textAlign: 'center' }} />
      </Flex>

      <Flex>
        {/* {store?.user?.isUsdtApprove ? (
          <LockButton disabled={!!isWithdrawLoading} onClick={withdraw} style={{ flex: '1' }}>
            {isWithdrawLoading ? <Dots>创建下一期 </Dots> : '创建下一期'}
          </LockButton>
        ) : (
          <LockButton disabled={!!isApproveLoading} onClick={approve} style={{ flex: '1' }}>
            {isApproveLoading ? <Dots>授权 </Dots> : '授权'}
          </LockButton>
        )} */}
        <LockButton disabled={!!isWithdrawLoading} onClick={withdraw} style={{ flex: '1' }}>
          {isWithdrawLoading ? <Dots>创建下一期 </Dots> : '创建下一期'}
        </LockButton>
      </Flex>
    </AdminBody>
  )
}

const CatStaking: React.FC<any> = () => {
  const t = useI18nText()

  const url = window.location.href

  useEffect(() => {
    const refs = url.split('ref=')
    if (refs.length > 1) {
      const ref = refs[1]
      console.log(`utils.isAddress(ref): ${utils.isAddress(ref)}`)
      if (utils.isAddress(ref)) {
        dispatch(setRef(ref))
      }
    }
  }, [url])

  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()
  const [inputAmount, setInputAmount] = useState('')
  const store = useAppSelector((state) => state.catStaking)

  const _amount = inputAmount?.slice(-1) === '.' ? inputAmount?.slice(0, -1) : inputAmount
  const limitAmount = useMemo(() => ethers.BigNumber.from(store.staking?.limitAmount || '0'), [store])
  const balance = useMemo(() => ethers.BigNumber.from(store.user?.balance || '0'), [store])
  const totalStakingAmount = useMemo(() => ethers.BigNumber.from(store.staking?.totalStakingAmount || '0'), [store])
  const amount = useMemo(() => ethers.BigNumber.from(store.user?.amount || '0'), [store])
  const pendingReward = store.user?.pendingReward
  useEffect(() => {
    const fetchDataAndUserData = async () => {
      fetchCatStakingData(chainId).then((data) => dispatch(setCatStakingData(data)))
      if (account) {
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingUserData(userData))
      }
    }
    // 执行一次初始逻辑
    // fetchDataAndUserData()

    // 设置定时器，每隔一段时间执行一次逻辑
    const intervalId = setInterval(fetchDataAndUserData, 3000) // 每分钟执行一次

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId)
  }, [account, dispatch, chainId])

  const [disabled, setDisabled] = useState(false)

  const onChange = useMemo(() => {
    return (checked) => {
      setInputAmount(balance.mul(checked).div('100').toString())
    }
  }, [balance])

  /* eslint-disable */
  const depositError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0) return t('Stake Clone')
    else if (inputAmount === '') return ''
    else if (store?.user?.tickCounts > store?.staking?.limitTick) return t('Limit Tick')
    else if (limitAmount.gt(inputAmount || '0')) return t('Limit Amount')
    else if (balance.lt(_amount)) return t('Insufficient Balance')
    else return ''
  }, [store, balance, _amount, inputAmount])

  /* eslint-disable */  
  const withdrawError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0) return t('Stake Clone')
    else if (amount.eq('0')) return t('Not Stake')
    else if (store.staking?.stageHeight > 0 && Date.now() / 1000 < Number(store.staking?.endTime))
      return t('Lock up period')
    else return ''
  }, [store, balance, _amount, inputAmount])

  const withdraw2Error = useMemo(() => {
    if (store.staking?.startTradeBlock === 0) return t('Stake Clone')
    else if (Number(pendingReward) == 0) return t('Not Reward')
    else return ''
  }, [store, balance, _amount, inputAmount])

  const { isLoading: isApproveLoading, approve } = usetCatmeApprove(getCatStakingAddress(chainId))
  const { isLoading: isDepositLoading, deposit } = useDeposit({ inputAmount, ref: store?.user?.ref })
  const { isLoading: isWithdrawLoading, withdraw } = useWithdraw()
  const { isLoading: isWithdraw2Loading, withdraw: withdraw2 } = useWithdraw2()

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <>
      <HomeBody>
        <div className="body">
          <div>
            <div className="zhu">
              <span>{t('Due to the large circulation, the pledge only displays the first 10 digits')}</span>
            </div>
            <div className="title">
              <p>{store?.staking?.stageHeight > 0 ? `第 ${store?.staking?.stageHeight} 期` : t('Preparing')}</p>
              <span>
                {' '}
                {Number(store?.staking?.stageHeight) > 0 &&
                  `${format(Number(store?.staking?.startTime) * 1000 || 0, 'yyyy-MM-dd HH:mm:ss')} -> ${format(
                    Number(store?.staking?.endTime) * 1000 || 0,
                    'yyyy-MM-dd HH:mm:ss'
                  )}`}
              </span>
            </div>

            <div className="data">
              <Flex flexDirection="column">
                <p>{t('Total Stake')}</p>
                <span>{getFirstTenCharacters(totalStakingAmount?.toString() || '')}</span>
              </Flex>

              <p style={{ margin: '0px 10px', height: '100%', width: '1px', background: 'rgb(229 231 235)' }} />
              <Flex flexDirection="column">
                <p>{t('My Stake')}</p>
                <span>{getFirstTenCharacters(amount?.toString() || '')}</span>
              </Flex>

              <p style={{ margin: '0px 10px', height: '100%', width: '1px', background: 'rgb(229 231 235)' }} />
              <Flex flexDirection="column">
                <p>{t('My USDT Reward')}</p>
                <span>{pendingReward || 0.0}</span>
              </Flex>
            </div>

            <div className="input">
              <Flex justifyContent="space-between" className="div1">
                <div>{t('Stake')}</div>
                <div>
                  {t('Balance')}: {getFirstTenCharacters(balance?.toString() || '')}
                </div>
              </Flex>
              <Flex justifyContent="space-between">
                <LockInput
                  value={inputAmount}
                  prefix={<Avatar src="/favicon.ico" />}
                  onChange={(e: any) => {
                    if (!Number.isNaN(e.target.value)) {
                      setInputAmount(e.target.value)
                    }
                  }}
                  suffix={
                    <Max
                      style={{ fontWeight: 600, color: '#e7b7ab', cursor: 'pointer' }}
                      onClick={() => setInputAmount(balance?.toString())}
                    >
                      Max
                    </Max>
                  }
                />
              </Flex>
            </div>
            <div className="button">
              <Slider defaultValue={0} disabled={disabled} onChange={onChange} />

              {store?.user?.isApprove ? (
                <>
                  <Flex>
                    <LockButton disabled={!!depositError || isDepositLoading} onClick={deposit} style={{ flex: '1' }}>
                      {isDepositLoading ? <Dots>{t('Stake')}</Dots> : depositError || t('Stake')}
                    </LockButton>
                    <p style={{ width: '10px' }} />
                    <LockButton
                      disabled={!!withdrawError || isWithdrawLoading}
                      onClick={withdraw}
                      style={{ flex: '1' }}
                    >
                      {isWithdrawLoading ? <Dots>{t('Cancel pledge')}</Dots> : withdrawError || t('Cancel pledge')}
                    </LockButton>
                  </Flex>
                  <p style={{ margin: '10px 10px', height: '100%', width: '1px', background: 'rgb(229 231 235)' }} />

                  <Flex>
                    <LockButton
                      disabled={!!withdraw2Error || isWithdraw2Loading}
                      onClick={withdraw2}
                      style={{ flex: '1' }}
                    >
                      {isWithdraw2Loading ? <Dots>{t('Claim Reward')}</Dots> : withdraw2Error || t('Claim Reward')}
                    </LockButton>
                  </Flex>
                </>
              ) : (
                <Flex>
                  <LockButton disabled={!!isApproveLoading} onClick={approve} style={{ flex: '1' }}>
                    {isApproveLoading ? <Dots>{t('Approve')}</Dots> : t('Approve')}
                  </LockButton>
                </Flex>
              )}
            </div>
          </div>

          {!!account && account === store?.staking?.owner && <Admin />}

        </div>
      </HomeBody>
    </>
  )
}

export default CatStaking
