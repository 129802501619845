import { spawn } from 'child_process'
import { MenuEntry } from 'pancake-uikit'

/* eslint-disable */
const config: MenuEntry[] = [
  {
    label: 'Stake',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Pledge mining USDT',
    icon: 'HomeIcon',
    href: '/catStaking',
  },
  {
    label: 'Combustion dividend BNB 🔥🔥🔥',
    icon: 'HomeIcon',
    href: '/burnCat',
  },
  {
    label: '质押LP3倍出局',
    icon: 'HomeIcon',
    href: '/lpStaking',
  },
  {
    label: '双质押LP金本位3倍出局挖PEIPEI 🔥🔥🔥',
    icon: 'HomeIcon',
    href: 'https://dapp.peipeicoinbsc.xyz/#/',
  },
  {
    label: '质押BNB+CATME挖新PEIPEI金本位3倍出局 🔥🔥🔥',
    icon: 'HomeIcon',
    href: 'http://peipeichina.space/#/',
  },
  {
    label: 'Flash exchange',
    icon: 'HomeIcon',
    href: '/1',
    modal: true,
  },
  {
    label: 'Launch pad',
    icon: 'HomeIcon',
    href: '/2',
    modal: true,
  },
  {
    label: 'Coinage',
    icon: 'HomeIcon',
    href: '/3',
    modal: true,
  },
  {
    label: 'Farm',
    icon: 'HomeIcon',
    href: '/4',
    modal: true,
  },
]

export default config
