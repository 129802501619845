import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BigNumber, ethers, utils } from 'ethers'
import {
  getCatmeTokenAddress,
  getBurnCatAddress,
  getNftMintAddress,
  getLpStakingAddress,
  getUsdtTokenAddress,
} from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import lpStaking_abi from 'config/abi/LpStaking.json'
import NFTMint_abi from 'config/abi/NFTMint.json'
import { Call, multicall } from 'utils/calls/multicall'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchLpStakingData = async (chainId: any = NETWORK_CHAIN_ID): Promise<any> => {
  const mintCalls = [
    'totalClaimable',
    'claimPrice',
    'claimAmount',
    'minClamins',
    'maxClamins',
    'claimPeriodEnd',
    'claimPeriodStart',
    'userClaimLimit',
  ].map((method) => {
    return {
      address: getNftMintAddress(chainId),
      name: method,
      params: [],
    }
  })
  const lpStakingCalls = [
    'startTradeBlock',
    'maxProfitMultiplier',
    'releaseRatio',
    'TotalBnbAmountProfitMultiplier',
    'TotalLockLpAmount',
    'TotalClaimLp',
    'TotalLockLpBnbAmount',
    'TotalHastenBnbAmount',

    'TotalRewardBnbAmount',
    'TotalEntryBnbAmount',
    '_price',
    'owner',
    'TotalClaimReward',
    'isClose',
  ].map((method) => {
    return {
      address: getLpStakingAddress(chainId),
      name: method,
      params: [],
    }
  })
  // 创建合约对象
  const result = await multicall(chainId, lpStaking_abi.concat(NFTMint_abi), mintCalls.concat(lpStakingCalls))

  const [
    [totalClaimable],
    [claimPrice],
    [claimAmount],
    [minClamins],
    [maxClamins],
    [claimPeriodEnd],
    [claimPeriodStart],
    [userClaimLimit],

    [startTradeBlock],
    [maxProfitMultiplier],
    [releaseRatio],
    [TotalBnbAmountProfitMultiplier],
    [TotalLockLpAmount],
    [TotalClaimLp],
    [TotalLockLpBnbAmount],
    [TotalHastenBnbAmount],
    [TotalRewardBnbAmount],
    [TotalEntryBnbAmount],
    [_price],
    [owner],
    [TotalClaimReward],
    [isClose]
  ] = result

  return {
    staking: {
      owner,
      isClose,
      startTradeBlock: Number(startTradeBlock.toString()),
      maxProfitMultiplier: Number(maxProfitMultiplier.toString()),
      releaseRatio: Number(releaseRatio.toString()),
      TotalBnbAmountProfitMultiplier: Number(utils.formatEther(TotalBnbAmountProfitMultiplier.toString())),
      TotalLockLpAmount: Number(utils.formatEther(TotalLockLpAmount.toString())),
      TotalClaimLp: TotalClaimLp.toString(),
      TotalClaimReward: Number(utils.formatEther(TotalClaimReward.toString())),
      TotalLockLpBnbAmount: Number(utils.formatEther(TotalLockLpBnbAmount.toString())),
      TotalHastenBnbAmount: Number(utils.formatEther(TotalHastenBnbAmount.toString())),
      TotalRewardBnbAmount: Number(utils.formatEther(TotalRewardBnbAmount.toString())),
      TotalEntryBnbAmount: Number(utils.formatEther(TotalEntryBnbAmount.toString())),
      price: _price.toString(),
    },

    mint: {
      totalClaimable: Number(totalClaimable.toString()),
      claimPrice: Number(utils.formatEther(claimPrice)),
      claimAmount: Number(utils.formatEther(claimAmount)),
      minClamins: Number(minClamins.toString()),
      maxClamins: Number(maxClamins.toString()),
      claimPeriodEnd: Number(claimPeriodEnd.toString()),
      claimPeriodStart: Number(claimPeriodStart.toString()),
      userClaimLimit: Number(userClaimLimit.toString()),
    },
  }
}

export const fetchLpStakingUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const calls = ['users', 'pendingReward', 'pendingToken', 'getUserLpData'].map((method) => {
    return {
      address: getLpStakingAddress(chainId),
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address: getNftMintAddress(chainId),
      name: 'userClaims',
      params: [account],
    },
    {
      address: getUsdtTokenAddress(chainId),
      name: 'allowance',
      params: [account, getNftMintAddress(chainId)],
    },
    {
      address: getUsdtTokenAddress(chainId),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getLpStakingAddress(chainId),
      name: 'getLogs',
      params: [account, 0, 100],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, lpStaking_abi.concat(NFTMint_abi).concat(Erc20_abi), calls.concat(mainCalls))
  const [
    users,
    [pendingReward],
    [pendingLp],
    getUserLpData,
    [userClaims],
    [usdtAllowance],
    [usdtBalance],
    [getLogs],
  ] = result
  console.log(getLogs)
  const [balanceOf, allowance, nftBalance, balanceBnb] = getUserLpData
  return {
    user: {
      nftMint: {
        userClaims: Number(userClaims.toString()),
        isApprove: usdtAllowance.toString() !== '0',
        balance: Number(utils.formatEther(usdtBalance.toString())),
      },
      lpStaking: {
        nftBalance: Number(nftBalance.toString()),
        isApprove: allowance.toString() !== '0',
        balance: utils.formatEther(balanceOf.toString()),
        balanceBnb: Number(utils.formatEther(balanceBnb.toString())),
        pendingReward: Number(utils.formatEther(`${pendingReward.toString()}`)),
        pendingLp: pendingLp.toString(),
        amount: Number(utils.formatEther(users?.amount.toString())),
        hastenBnbAmount: Number(utils.formatEther(users?.hastenBnbAmount.toString())) / 100,
        bnbAmountProfitMultiplier: Number(utils.formatEther(users?.bnbAmountProfitMultiplier.toString())),
        claimReward: Number(utils.formatEther(users?.claimReward.toString())),
        claimLp: users?.claimLp.toString(),
        bnbAmount: Number(utils.formatEther(users?.bnbAmount.toString())),
        logs: getLogs
          .map(({ amount, types, bnbAmount, time }) => {
            return {
              amount:
                Number(types.toString()) !== 2
                  ? utils.formatEther(amount.toString())
                  : utils.formatEther(BigNumber.from(bnbAmount.toString()).div('100')),
              bnbAmount: utils.formatEther(bnbAmount.toString()) ,
              types:
                Number(types.toString()) === 0 ? '质押 LP' : Number(types.toString()) === 1 ? '领取收益' : '加速释放',
              time: Number(time.toString()),
            }
          })
          .sort((a, b) => b.time - a.time),
      },
    },
  }
}

interface StakingState {
  user?: any
  staking?: any
  mint?: any
}

const initialState: StakingState = {}

export const LpStakingSlice = createSlice({
  name: 'lpStaking',
  initialState,
  reducers: {
    setLpStakingData: (state, action) => {
      state.staking = {
        ...state.staking,
        ...action.payload.staking,
      }
      state.mint = {
        ...state.mint,
        ...action.payload.mint,
      }
      // console.log(action)
    },
    setLpStakingUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      // console.log(action)
    },
    setLpRef: (state, action) => {
      state.user = {
        ...state.user,
        ref: action.payload,
      }
      // console.log(action)
    },
  },
})

// Actions
export const { setLpStakingData, setLpStakingUserData, setLpRef } = LpStakingSlice.actions

export default LpStakingSlice.reducer
