import { useWeb3React } from '@web3-react/core'
import { Button, Input, message } from 'antd'
import { Flex } from 'pancake-uikit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import {
  fetchLpStakingData,
  fetchLpStakingUserData,
  setLpRef,
  setLpStakingData,
  setLpStakingUserData,
} from 'state/lpStaking'
import { format } from 'date-fns'
import styled from 'styled-components'
import Dots from 'components/Loader/Dots'
import copy from 'copy-to-clipboard'
import useI18nText from 'hooks/useI18n'
import { utils } from 'ethers'
import { getLpStakingAddress, getNftMintAddress } from 'utils/addressHelpers'
import { useLockPosition, useLpApprove, useMint, useWithdraw, usetUsdtApprove } from './hook'

const LpStakingBody = styled.div`
  margin: 0px auto;
  margin-top: 20px;

  .list {
    width: 100%;
    height: 300px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #c56f6d;
    padding: 20px 24px;
    flex-direction: column;
    @media screen and (min-width: 360px) {
      padding: 20px 14px;
      margin-top: 20px;
    }
    @media screen and (min-width: 576px) {
      padding: 20px 24px;
      margin-top: 20px;
    }
    overflow: auto;
    .list-title {
      background-color: #ffefef;
      width: 100%;
      justify-content: space-between;
      padding: 10px 26px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 600;
      @media screen and (min-width: 360px) {
        padding: 10px 16px;
      }
      @media screen and (min-width: 576px) {
        padding: 10px 26px;
      }
      > div {
        flex: 1;
      }
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: right;
      }
    }
    .list-row {
      padding: 5px 26px;
      justify-content: space-between;
      font-size: 14px;
      @media screen and (min-width: 360px) {
        padding: 5px 16px;
      }
      @media screen and (min-width: 576px) {
        padding: 5px 26px;
      }
      > div {
        flex: 1;
      }
      > div:nth-child(2) {
        text-align: center;
      }
      > div:nth-child(3) {
        text-align: right;
      }
    }
  }
  .b-title {
    width: 100%;

    flex-direction: column;
    align-items: center;
    font-weight: 600;
    .b-2 {
      margin: 10px 0px;
    }
    .b-3 {
      border: 1px solid #c56f6d;
      border-radius: 20px;
      padding: 8px 46px;
      background: #ffefef;
      font-weight: 600;
      text-align: center;
      > p {
        font-size: 14px;
      }
    }
  }
  .user-lpStaking {
    @media screen and (min-width: 360px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
  }
  .input {
    @media screen and (min-width: 360px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
    button {
      @media screen and (min-width: 360px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 576px) {
        margin-top: 0px;
      }
    }
  }
  @media screen and (min-width: 360px) {
    width: 100%;
  }
  @media screen and (min-width: 576px) {
    width: 1080px;
  }
  .body {
    @media screen and (min-width: 360px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
    > div {
      width: '100%';
    }

    .rig {
      > div {
        @media screen and (min-width: 360px) {
          margin-top: 20px;
        }
        @media screen and (min-width: 576px) {
          margin-top: 0px;
        }
      }
    }
  }
  .win {
    > div {
      width: 100%;
      > div {
        padding: 5px 10px;
        border-radius: 8px;
        background: #d2abaa;
        border: 1px solid #c56f6d;
        margin-top: 10px;
      }
    }
    p {
      font-size: 12px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .data {
    h1 {
      font-size: 28px;
      span {
        margin-left: 10px;
        font-size: 24px;
      }
    }

    .data_1 {
      width: 100%;
      > div {
        padding: 5px 10px;
        border-radius: 8px;
        background: #d2abaa;
        border: 1px solid #c56f6d;
        margin-top: 10px;
        p {
          font-size: 12px;
        }
        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
  .div {
    border: 1px solid #c56f6d;
    background: #ffe0df;
    padding: 20px 24px;
    border-radius: 20px;
  }
  .ref {
    margin-top: 20px;
    border: 1px solid #c56f6d;
    background: #ffe0df;
    padding: 16px 24px;
    border-radius: 20px;
    h1,
    p,
    div {
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
    }
  }

  .nft_mint {
    border: 1px solid #c56f6d;
    background: #ffe0df;
    padding: 16px 24px;
    border-radius: 20px;

    > div {
      @media screen and (min-width: 360px) {
        flex-direction: column;
      }
      @media screen and (min-width: 576px) {
        flex-direction: row;
      }
    }
    .nft_img {
      @media screen and (min-width: 360px) {
        display: none;
      }
      @media screen and (min-width: 576px) {
        display: flex;
      }
      h1 {
        font-size: 28px;
      }
      div {
        margin-top: 20px;
      }
      img {
        width: 180px;
      }
    }

    .nft_input {
      img {
        width: 70px;
        z-index: 100;
      }
      h1 {
        font-size: 28px;
      }
      .mint-div {
        h1 {
          margin-left: -15px;
          padding: 5px 15px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          background: #e7b1b0;
        }
      }
    }

    .shu {
      margin: 20px 0px 10px 0px;
      font-size: 14px;
    }
  }

  .ant-btn-default:disabled {
    background: rgb(212, 212, 212, 0.8);
  }
`

const LockInput = styled(Input)`
  height: 48px;
  background: #d4d4d4;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  input {
    background-color: #d4d4d4;
    &:focus,
    &:focus-within,
    &:hover {
      background-color: #d4d4d4;
    }

    &:focus {
      box-shadow: none;
    }
  }
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #f3f3f3;
  }

  &:focus {
    box-shadow: none;
  }
`

const LockButton = styled(Button)`
  border: 1px solid #ffdad9;
  border-radius: 20px;
  margin-top: 20px;
  background-color: #c56f6d;
  &:hover {
    color: #d39c63 !important;
    border: 1px solid #e7b7ab !important;
  }
`

const Max = styled.div``
function getFirstTenCharacters(str) {
  if (str.length >= 10) {
    return str.substring(0, 10)
  }
  return '0.0'
}

const LpStaking = () => {
  const t = useI18nText()
  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()
  const [inputAmount, setInputAmount] = useState('')
  const [page, setPage] = useState(true)
  const [nftMintInputAmount, setNftMintInputAmount] = useState('1')
  const store = useAppSelector((state) => state.lpStaking)

  const url = window.location.href

  /* eslint-disable */
  useEffect(() => {
    const refs = url.split('ref=')
    if (refs.length > 1) {
      const ref = refs[1]
      console.log(`utils.isAddress(ref): ${utils.isAddress(ref)}`)
      if (utils.isAddress(ref)) {
        dispatch(setLpRef(ref))
      }
    }
  }, [url])

  useEffect(() => {
    const fetchDataAndUserData = async () => {
      fetchLpStakingData(chainId).then((data) => dispatch(setLpStakingData(data)))
      if (account) {
        const userData = await fetchLpStakingUserData(chainId, account)
        dispatch(setLpStakingUserData(userData))
      }
    }
    // 执行一次初始逻辑
    fetchDataAndUserData()

    // 设置定时器，每隔一段时间执行一次逻辑
    const intervalId = setInterval(fetchDataAndUserData, 3000) // 每分钟执行一次

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId)
  }, [account, dispatch, chainId])

  console.log(store)

  const _amount = inputAmount?.slice(-1) === '.' ? inputAmount?.slice(0, -1) : inputAmount

  /* eslint-disable */
  const mintError = useMemo(() => {
    if (store?.mint?.claimPeriodEnd === 0 && store?.mint?.claimPeriodStart === 0) return 'Mint Clone'
    else if (nftMintInputAmount === '') return ''
    else if (
      Number(nftMintInputAmount) < store?.mint?.minClamins ||
      Number(nftMintInputAmount) > store?.mint?.maxClamins
    )
      return 'limit'
    else if (Number(store?.user?.nftMint?.userClaims || 0) + Number(nftMintInputAmount) > store?.mint?.userClaimLimit)
      return `User Limit ${store?.mint?.userClaimLimit}`
    else if (Number(nftMintInputAmount) > store?.mint?.totalClaimable) return `remainder ${store?.mint?.totalClaimable}`
    else if (
      Number(Number(nftMintInputAmount) * Number(store?.mint?.claimPrice) > Number(store.user?.nftMint?.balance))
    )
      return t('Insufficient Balance')

    return ''
  }, [store, nftMintInputAmount])

  const depositError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0 || !store.staking?.isClose) return t('Clone')
    else if (inputAmount === '') return ''
    else if (store?.user?.lpStaking?.nftBalance === 0) return 'nft 0'
    else if (Number(_amount) === 0) return 'amount 0'
    else if (Number(store?.user?.lpStaking?.balance) < Number(_amount)) return t('Insufficient Balance')
    else return ''
  }, [store, _amount, inputAmount])

  const withdrawError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0) return t('Clone')
    else if (store?.user?.lpStaking?.amount === 0) return 'amount 0'
    else if (store?.user?.lpStaking?.pendingReward === 0) return t('Not Reward')
    else return ''
  }, [store, _amount, inputAmount])

  console.log(`_amount; ${_amount}`)
  const { isLoading: isUsdtApproveLoading, approve } = usetUsdtApprove(getNftMintAddress())
  const { isLoading: isLpApproveLoading, lpApprove } = useLpApprove(getLpStakingAddress())
  const { isLoading: isLockPositionLoading, lockPosition } = useLockPosition(_amount, store?.user?.ref)
  const { isLoading: isWithdrawLoading, withdraw } = useWithdraw()
  const { isLoading: isMintLoading, mint } = useMint(
    Number(nftMintInputAmount || 0) * Number(store?.mint?.claimPrice || 0),
    Number(nftMintInputAmount || 0)
  )
  return (
    <>
      {page ? (
        <LpStakingBody>
          <Flex className="body">
            <Flex flex="1" flexDirection="column">
              <Flex className="nft_mint" flexDirection="column">
                <Flex>
                  <Flex flexDirection="column" className="nft_img">
                    <h1>质押LP-3X</h1>
                    <div>
                      <img src="imgs/lp-1.png" alt="" />
                    </div>
                  </Flex>
                  <p style={{ width: '20px' }} />
                  <Flex flex="1" flexDirection="column" justifyContent="center" className="nft_input">
                    <Flex className="mint-div" alignItems="center">
                      <img src="imgs/lp-2.png" alt="" />
                      <h1>MINT NFT</h1>
                    </Flex>

                    <Flex justifyContent="space-between" style={{ fontSize: '12px', marginTop: '20px' }}>
                      <div>{store?.mint?.claimPrice || 10}USDT/1张</div>
                      <div>NFT持有者： {store?.user?.lpStaking?.nftBalance || 0}</div>
                    </Flex>
                    <LockInput
                      type="text"
                      style={{
                        border: '1px solid #c56f6d',
                        height: '38px',
                        background: '#d4d4d4',
                        textAlign: 'center',
                        marginTop: '5px',
                      }}
                      placeholder="输入铸造多少个NFT, 每一个 10 USDT 价格"
                      value={nftMintInputAmount}
                      onChange={(e: any) => {
                        if (!Number.isNaN(e.target.value)) {
                          setNftMintInputAmount(e.target.value)
                        }
                      }}
                    />
                    {store?.user?.nftMint?.isApprove ? (
                      <LockButton disabled={!!isMintLoading || !!mintError} onClick={mint}>
                        {isMintLoading ? <Dots>{t('Mint')}</Dots> : mintError || t('Mint')}
                      </LockButton>
                    ) : (
                      <LockButton disabled={!!isUsdtApproveLoading} onClick={approve}>
                        {isUsdtApproveLoading ? <Dots>{t('Approve')}</Dots> : t('Approve')}
                      </LockButton>
                    )}
                  </Flex>
                </Flex>

                <Flex className="shu">说明: 持有 Catme NFT 才能拥有质押的资格</Flex>
              </Flex>

              <Flex className="ref" flexDirection="column">
                <h1>邀请好友加速释放</h1>
                <p>我的邀请链接</p>
                <Flex style={{ width: '100%' }}>
                  <LockInput
                    type="text"
                    value={`http://catme.xyz/#/lpStaking?ref=${account}`}
                    style={{ border: '1px solid #c56f6d', height: '38px', background: '#d4d4d4' }}
                  />
                  <LockButton
                    style={{ margin: ' 0px', height: '38px', padding: '0px 20px' }}
                    onClick={() => {
                      copy(`http://catme.xyz/#/lpStaking?ref=${account}`)
                      message.success('copy success')
                    }}
                  >
                    复制
                  </LockButton>
                </Flex>
                <span>说明: 直推加速释放 10%</span>
              </Flex>
            </Flex>
            <p style={{ width: '20px' }} />
            <Flex flex="1" className="rig" flexDirection="column" justifyContent="space-between">
              <Flex className="data div" flexDirection="column">
                <h1>
                  质押LP<span style={{ color: '#ff5b57' }}>3倍收益出局</span>
                </h1>
                <Flex className="data_1">
                  <Flex flex="1" flexDirection="column">
                    <p>
                      我的 LP 价值:
                      <span style={{ fontSize: '8px' }}>
                        {(store?.user?.lpStaking?.balanceBnb &&
                          Number(store?.user?.lpStaking?.balanceBnb).toFixed(5)) ||
                          0} bnb
                      </span>
                    </p>
                    <span>
                      {(store?.user?.lpStaking?.balance && Number(store?.user?.lpStaking?.balance).toFixed(5)) || 0}
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column">
                    {' '}
                    <p>
                      我已质押LP价值:
                      <span style={{ fontSize: '8px' }}>
                        {(store?.user?.lpStaking?.bnbAmount && Number(store?.user?.lpStaking?.bnbAmount).toFixed(5)) ||
                          0} bnb
                      </span>
                    </p>
                    <span>
                      {' '}
                      {(store?.user?.lpStaking?.amount && Number(store?.user?.lpStaking?.amount || 0).toFixed(5)) || 0}
                    </span>
                  </Flex>
                </Flex>
                <Flex className="data_1">
                  <Flex flex="1" flexDirection="column" style={{ background: '#fbb468' }}>
                    <p>
                      全网总质押LP价值:
                      <span style={{ fontSize: '8px' }}>
                        {(store?.staking?.TotalLockLpBnbAmount &&
                          Number(store?.staking?.TotalLockLpBnbAmount).toFixed(5)) ||
                          0} bnb
                      </span>
                    </p>
                    <span>
                      {store?.staking?.TotalLockLpAmount && Number(store?.staking?.TotalLockLpAmount || 0).toFixed(5)}
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column" style={{ background: '#fbb468' }}>
                    {' '}
                    <p>
                      全网总收益 catme 价值:
                      <span style={{ fontSize: '8px' }}>
                        {(store?.staking?.TotalClaimReward && Number(store?.staking?.TotalClaimReward).toFixed(5)) || 0} bnb
                        （已领取）
                      </span>
                    </p>
                    <span>
                      {(store?.staking?.TotalClaimLp && getFirstTenCharacters(store?.staking?.TotalClaimLp)) || 0}
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex className="input div">
                <LockInput
                  placeholder="输入质押的 LP"
                  type="text"
                  value={inputAmount}
                  style={{ border: '1px solid #c56f6d', height: '38px', background: '#d4d4d4' }}
                  onChange={(e: any) => {
                    if (!Number.isNaN(e.target.value)) {
                      setInputAmount(e.target.value)
                    }
                  }}
                  suffix={
                    <Max
                      style={{ fontWeight: 600, color: '#c56f6d', cursor: 'pointer' }}
                      onClick={() => {
                        const lpBalance = utils.parseEther(store?.user?.lpStaking?.balance)
                        setInputAmount(utils.formatEther(lpBalance.sub(utils.parseEther('0.000001'))))
                        // if(lp > 0){
                        //   setInputAmount(`${Number(store?.user?.lpStaking?.balance) }`)
                        // }else{
                        //   setInputAmount(store?.user?.lpStaking?.balance?.toString())
                        // }
                      }}
                    >
                      Max
                    </Max>
                  }
                />{' '}
                {store?.user?.lpStaking?.isApprove ? (
                  <LockButton
                    style={{ height: '38px', padding: '0px 50px' }}
                    disabled={!!isLockPositionLoading || !!depositError}
                    onClick={lockPosition}
                  >
                    {isLockPositionLoading ? <Dots>{t('质押')}</Dots> : depositError || t('质押')}
                  </LockButton>
                ) : (
                  <LockButton
                    style={{ height: '38px', padding: '0px 50px' }}
                    disabled={!!isLpApproveLoading}
                    onClick={lpApprove}
                  >
                    {isLpApproveLoading ? <Dots>{t('Approve')}</Dots> : t('Approve')}
                  </LockButton>
                )}
              </Flex>
              <Flex className="win div" flexDirection="column">
                <Flex className="user-lpStaking">
                  <Flex flex="1" flexDirection="column">
                    <p>待领取收益</p>
                    <span>
                      {(store?.user?.lpStaking?.pendingReward &&
                        Number(store?.user?.lpStaking?.pendingReward || 0).toFixed(7)) ||
                        0}{' '}
                      BNB
                    </span>
                    <span>
                      {(store?.user?.lpStaking?.pendingLp &&
                        getFirstTenCharacters(store?.user?.lpStaking?.pendingLp)) ||
                        0}{' '}
                      catme
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column">
                    <p>可领取收益</p>
                    <span>
                      {(store?.user?.lpStaking?.bnbAmountProfitMultiplier &&
                        (
                          Number(store?.user?.lpStaking?.bnbAmountProfitMultiplier || 0) -
                          Number(store?.user?.lpStaking?.claimReward || 0)
                        ).toFixed(7)) ||
                        0}{' '}
                      BNB
                    </span>
                  </Flex>
                  <p style={{ width: '5px' }} />
                  <Flex flex="1" flexDirection="column">
                    <p>已领取收益</p>
                    <span>
                      {(store?.user?.lpStaking?.claimReward &&
                        Number(store?.user?.lpStaking?.claimReward || 0).toFixed(7)) ||
                        0}{' '}
                      BNB
                    </span>
                  </Flex>
                </Flex>
                <Flex>
                  <LockButton
                    style={{ width: '100%', height: '38px' }}
                    disabled={!!isWithdrawLoading || !!withdrawError}
                    onClick={withdraw}
                  >
                    {isWithdrawLoading ? <Dots>{t('领取')}</Dots> : withdrawError || t('领取')}
                  </LockButton>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <LockButton style={{ width: '100%', height: '38px', background: '#ffa46d' }} onClick={() => setPage(false)}>
              查看奖励明细
            </LockButton>
          </Flex>
        </LpStakingBody>
      ) : (
        <LpStakingBody>
          <Flex className="div">
            <Flex className="b-title">
              <div className="b-1">奖励总计</div>
              <div className="b-2">{Number(store?.staking?.TotalClaimLp || 0).toFixed(5)}</div>
              <div className="b-3">
                <p> 加速 </p>
                <span>{(store?.user?.lpStaking?.hastenBnbAmount && store?.user?.lpStaking?.hastenBnbAmount) || 0}</span>
              </div>
            </Flex>
          </Flex>

          <Flex className="list">
            <Flex className="list-title">
              <div>时间</div>
              <div>数量</div>
              <div>概览</div>
            </Flex>
            {(store?.user?.lpStaking?.logs || []).map((log) => (
              <Flex className="list-row">
                <div>{format(Number(log.time) * 1000 || 0, 'MM-dd HH:mm')}</div>
                <div>{log.amount}</div>
                <div>{log.types}</div>
              </Flex>
            ))}
          </Flex>
          <Flex>
            <LockButton style={{ width: '100%', height: '38px', background: '#ffa46d' }} onClick={() => setPage(true)}>
              返回
            </LockButton>
          </Flex>
        </LpStakingBody>
      )}
    </>
  )
}

export default LpStaking
