import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers, utils } from 'ethers'
import { getCatmeTokenAddress, getBurnCatAddress } from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import BurnCat_abi from 'config/abi/BurnCat.json'
import { Call, multicall } from 'utils/calls/multicall'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchBurnCatData = async (chainId: any = NETWORK_CHAIN_ID): Promise<any> => {
  const tokenCalls = [
    'startTradeBlock',
    'TotalBurnAmount',
    'TotalBurnBnbAmount',
    'getTotalRewardBnbAmount',
    'TotalEntryBnbAmount',
    'closeLimit',
    'fundingLimit',
    'userFundingMinLimit',
    'userFundingMaxLimit',
    'userClaimLimit',
    '_price',
    'owner',
  ].map((method) => {
    return {
      address: getBurnCatAddress(chainId),
      name: method,
      params: [],
    }
  })
  // 创建合约对象
  const result = await multicall(chainId, BurnCat_abi, tokenCalls)

  const [
    [startTradeBlock],
    [TotalBurnAmount],
    [TotalBurnBnbAmount],
    [TotalRewardBnbAmount],
    [TotalEntryBnbAmount],
    [closeLimit],
    [fundingLimit],
    [userFundingMinLimit],
    [userFundingMaxLimit],
    [userClaimLimit],
    [_price],
    [owner],
  ] = result

  return {
    staking: {
      owner,
      startTradeBlock: Number(startTradeBlock.toString()),
      totalBurnAmount: TotalBurnAmount.toString(),
      totalBurnBnbAmount: Number(utils.formatEther(TotalBurnBnbAmount.toString())),
      totalRewardBnbAmount: utils.formatEther(TotalRewardBnbAmount.toString()),
      totalEntryBnbAmount: utils.formatEther(TotalEntryBnbAmount.toString()),
      closeLimit,
      fundingLimit: Number(utils.formatEther(fundingLimit.toString())),
      userFundingMinLimit: Number(utils.formatEther(userFundingMinLimit.toString())),
      userFundingMaxLimit: Number(utils.formatEther(userFundingMaxLimit.toString())),
      userClaimLimit: Number(userClaimLimit.toString()),
      price: _price.toString(),
    },
  }
}

export const fetchBurnCatUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const tokenCalls = ['users', 'pendingReward', 'userClaims'].map((method) => {
    return {
      address: getBurnCatAddress(chainId),
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address: getCatmeTokenAddress(chainId),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getCatmeTokenAddress(chainId),
      name: 'allowance',
      params: [account, getBurnCatAddress(chainId)],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, BurnCat_abi.concat(Erc20_abi), tokenCalls.concat(mainCalls))
  const [users, [pendingReward], [userClaims], [balanceOf], [allowance]] = result


  return {
    user: {
      isApprove: allowance.toString() !== '0',
      balance: balanceOf.toString(),
      pendingReward: Number(userClaims.toString()) === 0 ? Number(utils.formatEther(`${pendingReward.toString()}`)) : 0,
      amount: users?.amount.toString(),
      bnbAmount: Number(utils.formatEther(users?.bnbAmount.toString())),
      userClaims: Number(userClaims.toString())
    },
  }
}

interface StakingState {
  user?: any
  staking?: any
}

const initialState: StakingState = {}

export const BurnCatSlice = createSlice({
  name: 'burnCat',
  initialState,
  reducers: {
    setBurnCatData: (state, action) => {
      state.staking = {
        ...state.staking,
        ...action.payload.staking,
      }
      // console.log(action)
    },
    setBurnCatUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      // console.log(action)
    },
    setBurnRef: (state, action) => {
      state.user = {
        ...state.user,
        ref: action.payload,
      }
      // console.log(action)
    },
  },
})

// Actions
export const { setBurnCatData, setBurnCatUserData, setBurnRef } = BurnCatSlice.actions

export default BurnCatSlice.reducer
