import { useMemo } from 'react'
import {
  getBurnCatContract,
  getCatStakingContract,
  getERC20Contract, getLpStakingContract, getNftMintContract, getStakingContract,
} from 'utils/contractHelpers'
import { BurnCat, CatStaking, Erc20, LpStaking, NFTMint, Staking  } from 'config/abi/types/index'
import { useActiveWeb3React } from './index'
import { getProviderOrSigner } from '../utils'

export const useERC20Contract = (address: string, withSignerIfPossible = true): Erc20 => {
  const { library, account } = useActiveWeb3React()
  return useMemo(
    () => getERC20Contract(address, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, address, library, withSignerIfPossible]
  )
}

export const useStakingContract = (withSignerIfPossible = true): Staking => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getStakingContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}

export const useCatStakingContract = (withSignerIfPossible = true): CatStaking => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getCatStakingContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}


export const useBurnCatContract = (withSignerIfPossible = true): BurnCat => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getBurnCatContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}

export const useLpStakingContract = (withSignerIfPossible = true): LpStaking => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getLpStakingContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}


export const useNftMintContract = (withSignerIfPossible = true): NFTMint => {
  const { library, account, chainId } = useActiveWeb3React()
  return useMemo(
    () => getNftMintContract(chainId, withSignerIfPossible ? getProviderOrSigner(library, account) : library),
    [account, library, chainId, withSignerIfPossible]
  )
}


export default {}
