/* eslint-disable no-undef */
import React, { useCallback } from 'react'
import { allLanguages } from 'constants/localisation/languageCodes'
import styled from 'styled-components'
import { Text } from 'pancake-uikit/components/Text'
import { SvgProps } from '../../../components/Svg'
import Button from '../../../components/Button/Button'
import * as IconModule from '../icons'
import { LangType } from '../types'

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> }
const { LanguageIcon } = Icons

interface Props {
  currentLang: string
  langs: LangType[]
  setLang: (lang: LangType) => void
}
export const DivToken = styled(Button)`
  padding: 0px 0px;
`
const LangSelector: React.FC<Props> = ({ currentLang, setLang }) => {
  const [EN, ZHCN] = allLanguages
  const onLang = useCallback(
    (lang: LangType) => {
      setLang(lang)
    },
    [setLang]
  )

  return (
    <DivToken
      variant="text"
      className="isZh"
      startIcon={
        <LanguageIcon color="#000" width="24px" className={!(currentLang?.toUpperCase() === 'EN') ? '' : 'isZh'} />
      }
      onClick={() => {
        onLang(currentLang === 'en' ? ZHCN : EN)
      }}
    >
      {/* <Text color="#000">{currentLang?.toUpperCase() === "ZH-CN" ? 'CN' : currentLang?.toUpperCase()}</Text> */}
    </DivToken>
  )
}

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang)
