import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import { useBurnCatContract, useERC20Contract } from 'hooks/useContract'
import { ethers } from 'ethers'
import { estimateGas } from 'utils/calls/estimateGas'
import { getCatmeTokenAddress } from 'utils/addressHelpers'
import { message } from 'antd'
import { fetchBurnCatData, fetchBurnCatUserData, setBurnCatData, setBurnCatUserData } from 'state/burnCat'
import { match } from 'utils/match'

export const useDeposit = ({ inputAmount, ref }: any) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useBurnCatContract()

  // 加密数据
  const deposit = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(
        stakingContract,
        'burnPosition',
        [inputAmount, ref || ethers.constants.AddressZero],
        1000
      )
      const response = await stakingContract.burnPosition(inputAmount, ref || ethers.constants.AddressZero, {
        gasLimit,
      })
      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchBurnCatData(chainId)
        const userData = await fetchBurnCatUserData(chainId, account)
        dispatch(setBurnCatData(data))
        dispatch(setBurnCatUserData(userData))
        message.success('Burn success')
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, ref, account, inputAmount])

  return { isLoading, deposit }
}

export const useWithdraw = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useBurnCatContract()

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(stakingContract, 'withdraw', [], 1000)
      const response = await stakingContract.withdraw({ gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchBurnCatData(chainId)
        const userData = await fetchBurnCatUserData(chainId, account)
        dispatch(setBurnCatData(data))
        dispatch(setBurnCatUserData(userData))
        message.success('Withdraw success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, account])

  return { isLoading, withdraw }
}

export const usetCatmeApprove = (sender) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(getCatmeTokenAddress(chainId))

  // 加密数据
  const approve = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchBurnCatUserData(chainId, account)
        dispatch(setBurnCatUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Approve error')
    }
  }, [dispatch, chainId, account])

  return { isLoading, approve }
}

export default {}
