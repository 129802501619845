import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import { useBurnCatContract, useERC20Contract, useLpStakingContract, useNftMintContract } from 'hooks/useContract'
import { ethers, utils } from 'ethers'
import { estimateGas } from 'utils/calls/estimateGas'
import { getCatmeTokenAddress, getLpTokenAddress, getUsdtTokenAddress } from 'utils/addressHelpers'
import { message } from 'antd'
import { fetchBurnCatData, fetchBurnCatUserData, setBurnCatData, setBurnCatUserData } from 'state/burnCat'
import { match } from 'utils/match'
import { fetchLpStakingData, fetchLpStakingUserData, setLpStakingData, setLpStakingUserData } from 'state/lpStaking'

export const useWithdraw = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useLpStakingContract()

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(stakingContract, 'withdraw', [], 1000)
      const response = await stakingContract.withdraw({ gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchLpStakingData(chainId)
        const userData = await fetchLpStakingUserData(chainId, account)
        dispatch(setLpStakingData(data))
        dispatch(setLpStakingUserData(userData))
        message.success('Withdraw success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, stakingContract, account])

  return { isLoading, withdraw }
}
export const useLockPosition = (value: string, ref: string) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useLpStakingContract()

  // 加密数据
  const lockPosition = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(
        stakingContract,
        'lockPosition',
        [utils.parseEther(`${value}`), ref || ethers.constants.AddressZero],
        1000
      )
      const response = await stakingContract.lockPosition(
        utils.parseEther(`${value}`),
        ref || ethers.constants.AddressZero,
        { gasLimit: gasLimit }
      )

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchLpStakingData(chainId)
        const userData = await fetchLpStakingUserData(chainId, account)
        dispatch(setLpStakingData(data))
        dispatch(setLpStakingUserData(userData))
        message.success('Deposit success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, stakingContract, value, ref, account])

  return { isLoading, lockPosition }
}

export const useMint = (value: number, count: number) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useNftMintContract()

  // 加密数据
  const mint = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      console.log(`value: ${value}`)
      console.log(`count: ${count}`)
      const gasLimit = await estimateGas(stakingContract, 'claim', [utils.parseEther(`${value}`), count], 1000)
      const response = await stakingContract.claim(utils.parseEther(`${value}`), count, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchLpStakingData(chainId)
        const userData = await fetchLpStakingUserData(chainId, account)
        dispatch(setLpStakingData(data))
        dispatch(setLpStakingUserData(userData))
        message.success('mint success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, stakingContract, value, count, account])

  return { isLoading, mint }
}

export const usetUsdtApprove = (sender) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(getUsdtTokenAddress(chainId))

  // 加密数据
  const approve = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchLpStakingUserData(chainId, account)
        dispatch(setLpStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, account])

  return { isLoading, approve }
}


export const useLpApprove = (sender) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(getLpTokenAddress(chainId))

  // 加密数据
  const lpApprove = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchLpStakingUserData(chainId, account)
        dispatch(setLpStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.error(match(error.toString()))
    }
  }, [dispatch, chainId, account])

  return { isLoading, lpApprove }
}

export default {}
