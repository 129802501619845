import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useState } from 'react'
import { useAppDispatch } from 'state'
import { formatEther, formatUnits, parseEther } from '@ethersproject/units'
import { useCatStakingContract, useERC20Contract, useStakingContract } from 'hooks/useContract'
import { ethers, utils } from 'ethers'
import { estimateGas } from 'utils/calls/estimateGas'
import { getCatmeTokenAddress, getUsdtTokenAddress } from 'utils/addressHelpers'
import { message } from 'antd'
import {
  fetchCatStakingData,
  fetchCatStakingUserData,
  setCatStakingData,
  setCatStakingUserData,
} from 'state/catStaking'

export const useDeposit = ({ inputAmount, ref }: any) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useCatStakingContract()

  // 加密数据
  const deposit = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      console.log(inputAmount)
      const gasLimit = await estimateGas(stakingContract, 'deposit', [inputAmount], 1000)
      const response = await stakingContract.deposit(inputAmount, {
        gasLimit,
      })
      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchCatStakingData(chainId)
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingData(data))
        dispatch(setCatStakingUserData(userData))
        message.success('Deposit success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Deposit error')
    }
  }, [dispatch, chainId, ref, account, inputAmount])

  return { isLoading, deposit }
}

export const useWithdraw = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useCatStakingContract()

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(stakingContract, 'withdraw()', [], 1000)
      const response = await stakingContract['withdraw()']({ gasLimit: gasLimit })
      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchCatStakingData(chainId)
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingData(data))
        dispatch(setCatStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Withdraw success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Withdraw error')
    }
  }, [dispatch, chainId, account])

  return { isLoading, withdraw }
}

export const useWithdraw2 = () => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useCatStakingContract()

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(stakingContract, 'withdraw(uint256)', [0], 1000)
      const response = await stakingContract['withdraw(uint256)'](0, { gasLimit: gasLimit })
      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchCatStakingData(chainId)
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingData(data))
        dispatch(setCatStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Withdraw success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Withdraw error')
    }
  }, [dispatch, chainId, account])

  return { isLoading, withdraw }
}

export const useAddStage = ({startTime, endTime, inputAmount}) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const stakingContract = useCatStakingContract()

  // 加密数据
  const withdraw = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(stakingContract, 'addStage', [startTime, endTime, utils.parseEther(inputAmount)], 1000)
      const response = await stakingContract.addStage(startTime, endTime, utils.parseEther(inputAmount), { gasLimit: gasLimit })
      const receipt = await response.wait()
      if (receipt.status) {
        const data = await fetchCatStakingData(chainId)
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingData(data))
        dispatch(setCatStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Withdraw success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Withdraw error')
    }
  }, [dispatch, chainId, account, startTime, endTime, inputAmount])

  return { isLoading, withdraw }
}

export const usetCatmeApprove = (sender) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(getCatmeTokenAddress(chainId))

  // 加密数据
  const approve = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Approve error')
    }
  }, [dispatch, chainId, account])

  return { isLoading, approve }
}


export const usetUsdtApprove = (sender) => {
  const { chainId, account } = useActiveWeb3React()
  const [isLoading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const contract = useERC20Contract(getUsdtTokenAddress(chainId))

  // 加密数据
  const approve = useCallback(async () => {
    /* eslint-disable */
    try {
      setLoading(true)
      const gasLimit = await estimateGas(contract, 'approve', [sender, ethers.constants.MaxUint256], 1000)
      const response = await contract.approve(sender, ethers.constants.MaxUint256, { gasLimit: gasLimit })

      const receipt = await response.wait()
      if (receipt.status) {
        const userData = await fetchCatStakingUserData(chainId, account)
        dispatch(setCatStakingUserData(userData))
        // dispatch(fetchCurrentPresaleDataAsync({ chainId }))
        // dispatch(fetchCurrentPresaleUserDataAsync({ chainId, account }))
        message.success('Approve success')
      }
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      message.success('Approve error')
    }
  }, [dispatch, chainId, account])

  return { isLoading, approve }
}

export default {}
