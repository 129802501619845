import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch, useAppSelector } from 'state'
import styled from 'styled-components'
import { Flex, useMatchBreakpoints } from 'pancake-uikit'
import { Avatar, Button, Input, Slider, message } from 'antd'
import { fetchStakingData, fetchStakingUserData, setRef, setStakingData, setStakingUserData } from 'state/staking'
import { BigNumber, ethers, utils } from 'ethers'
import Dots from 'components/Loader/Dots'
import copy from 'copy-to-clipboard'
import { fetchBurnCatData, fetchBurnCatUserData, setBurnCatData, setBurnCatUserData, setBurnRef } from 'state/burnCat'
import { getBurnCatAddress, getStakingAddress } from 'utils/addressHelpers'
import useI18nText from 'hooks/useI18n'
import { useDeposit, useWithdraw, usetCatmeApprove } from './hook'

const HomeBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  .flex-top {
    @media screen and (min-width: 360px) {
      height: 50px;
      width: 100%;
    }
    @media screen and (min-width: 576px) {
      height: 0px;
      width: 100%;
    }
  }
  .flex {
    height: 155px;
    width: 100%;
    /* display: flex; */

    display: flex;
    @media screen and (min-width: 360px) {
      position: none;
      bottom: 20px;
      height: 40px;

      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      position: fixed;
      bottom: 0px;
      height: 155px;
      margin-top: 0px;
    }
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-left: 10px;
      @media screen and (min-width: 360px) {
        width: 96px;
        /* height: 100vh; */
      }
      @media screen and (min-width: 576px) {
        width: 150px;
      }
    }
  }
  .body {
    height: 470px;
    border-radius: 20px;
    /* background-color: #fff; */
    > div:nth-child(1) {
      background-color: #fff;
      border-radius: 20px;
      padding: 10px 0px 40px 0px;
    }
    margin-top: 40px;

    @media screen and (min-width: 360px) {
      width: 100%;
      padding: 0px 0px;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }

    .title {
      p {
        color: rgb(75 85 99 / var(1));
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        margin: 20px 0px 5px 0px;
      }
      span {
        font-size: 1rem;
        line-height: 1;
        color: rgb(31 41 55 / 1);
        font-weight: 600;
      }
    }

    .data {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      > div {
        padding: 0px 10px;
      }
      p {
        color: rgb(107 114 128);
        font-weight: 600;
        font-size: 0.85rem;
        line-height: 1rem;
        margin: 10px 0px 5px 0px;
      }
      span {
        font-size: 0.75rem;
        line-height: 1;
        color: rgb(107 114 128);
        font-weight: 600;
      }
    }

    .input {
      border-radius: 5px;
      padding: 10px;
      border: 1px solid #e5e7eb;
      margin: 20px 20px 0px 20px;
      .div1 {
        div {
          color: rgb(148 163 184);
          font-size: 12px;
          line-height: 1.25rem;
        }
      }
    }

    .button {
      margin: 40px 20px 0px 20px;

      .ant-slider-track {
        background-color: rgb(255, 218, 217);
      }
      .ant-slider-handle::after {
        box-shadow: 0 0 0 2px #e7b7ab;
      }

      .ant-btn {
        background: '#fff';
      }
    }
  }

  .zhu {
    padding: 20px;
    @media screen and (min-width: 360px) {
      width: 100%;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }
    font-size: 15px;
  }
  .ref {
    border-radius: 20px;
    background-color: #fff;
    margin-top: 40px;
    margin-top: 20px;
    padding: 20px;
    @media screen and (min-width: 360px) {
      width: 100%;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1;
      color: rgb(107 114 128);
      font-weight: 600;
      margin: 5px 0px;
      text-align: left;
    }
    h1 {
      text-align: left;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
`
const Max = styled.div``

const LockInput = styled(Input)`
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  > input {
    background-color: #f3f3f3;
    &:focus,
    &:focus-within,
    &:hover {
      background-color: #f3f3f3;
    }

    &:focus {
      box-shadow: none;
    }
  }
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #f3f3f3;
  }

  &:focus {
    box-shadow: none;
  }
`

const LockButton = styled(Button)`
  border: 1px solid #ffdad9;
  /* background-color: '#ffdad9' !important; */
  background-color: rgb(231, 183, 171);
  &:hover {
    color: #d39c63 !important;
    border: 1px solid #e7b7ab !important;
  }
`

/* eslint-disable */
function getFirstTenCharacters(str) {
  if (str.length >= 10) {
    return str.substring(0, 10)
  } else {
    return '0.0'
  }
}

const BurnCat: React.FC<any> = () => {
  const t = useI18nText()

  const url = window.location.href

  useEffect(() => {
    const refs = url.split('ref=')
    if (refs.length > 1) {
      const ref = refs[1]
      console.log(`utils.isAddress(ref): ${utils.isAddress(ref)}`)
      if (utils.isAddress(ref)) {
        dispatch(setBurnRef(ref))
      }
    }
  }, [url])

  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()
  const [inputAmount, setInputAmount] = useState('')
  const store = useAppSelector((state) => state.burnCat)

  console.log(store)

  const _amount = inputAmount?.slice(-1) === '.' ? inputAmount?.slice(0, -1) : inputAmount

  const balance = useMemo(() => ethers.BigNumber.from(store.user?.balance || '0'), [store])
  // const pendingReward = useMemo(() => ethers.BigNumber.from(store.user?.pendingReward || '0'), [store])

  useEffect(() => {
    const fetchDataAndUserData = async () => {
      fetchBurnCatData(chainId).then((data) => dispatch(setBurnCatData(data)))
      if (account) {
        const userData = await fetchBurnCatUserData(chainId, account)
        dispatch(setBurnCatUserData(userData))
      }
    }
    // 执行一次初始逻辑
    fetchDataAndUserData()

    // 设置定时器，每隔一段时间执行一次逻辑
    const intervalId = setInterval(fetchDataAndUserData, 3000) // 每分钟执行一次

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId)
  }, [account, dispatch, chainId])

  const [disabled, setDisabled] = useState(false)

  const onChange = useMemo(() => {
    return (checked) => {
      setInputAmount(balance.mul(checked).div('100').toString())
    }
  }, [balance])

  const bnbPrice = BigNumber.from(_amount || '0').mul(store?.staking?.price || '0')
  console.log(bnbPrice && utils.formatUnits(bnbPrice, '48'))

  /* eslint-disable */
  const depositError = useMemo(() => {
    const burnAmount = Number(utils.formatUnits(bnbPrice, '48'))
    if (store.staking?.startTradeBlock === 0) return t('Burn Clone')
    else if (inputAmount === '') return ''
    else if (store?.user?.userClaims > 0) return t('Limit')
    else if (
      store?.staking?.closeLimit &&
      Number(store?.staking?.totalBurnBnbAmount) + burnAmount > Number(store?.staking?.fundingLimit)
    )
      return t('Total Burn Limit')
    else if (
      store?.staking?.closeLimit &&
      Number(store?.user?.bnbAmount) + burnAmount < Number(store?.staking?.userFundingMinLimit)
    )
      return t('User Burn Min Limit')
    else if (
      store?.staking?.closeLimit &&
      Number(store?.user?.bnbAmount) + burnAmount > Number(store?.staking?.userFundingMaxLimit)
    )
      return t('User Burn Max Limit')
    else if (balance.lt(_amount)) return t('Insufficient Balance')
    else return ''
  }, [store, balance, _amount, inputAmount])

  /* eslint-disable */
  const withdrawError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0) return t('Burn Clone')
    else if (store.user?.bnbAmount === 0) return t('Not Burn')
    else if (store.user?.pendingReward === 0) return t('Not Reward')
    else return ''
  }, [store, balance, _amount, inputAmount])

  const { isLoading: isApproveLoading, approve } = usetCatmeApprove(getBurnCatAddress(chainId))
  const { isLoading: isDepositLoading, deposit } = useDeposit({ inputAmount, ref: store?.user?.ref })
  const { isLoading: isWithdrawLoading, withdraw } = useWithdraw()

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <>
      <HomeBody>
        <div className="body">
          <div>
            <div className="zhu">
              <span>{t('Due to the large circulation, the pledge only displays the first 10 digits')}</span>
            </div>
            <div className="title">
              <p>{t('Total Burn🔥🔥🔥')}</p>
              <span>{getFirstTenCharacters(store?.staking?.totalBurnAmount || '0')}</span>
            </div>

            <div className="data">
              <Flex flexDirection="column">
                <p>{t('My Burn🔥')}</p>
                <span>{getFirstTenCharacters(store?.user?.amount || '0')}</span>
              </Flex>
              <p style={{ margin: '0px 10px' }} />
              <Flex flexDirection="column">
                <p>{t('My Burn🔥 BNB')}</p>
                <span>{(store?.user?.bnbAmount && Number(store?.user?.bnbAmount).toFixed(6)) || 0}</span>
              </Flex>

              <p style={{ margin: '0px 10px', height: '100%', width: '1px', background: 'rgb(229 231 235)' }} />
              <Flex flexDirection="column">
                <p>{t('My BNB Reward')}</p>
                <span>{(store?.user?.pendingReward && Number(store?.user?.pendingReward).toFixed(9)) || 0}</span>
              </Flex>
            </div>

            <div className="input">
              <Flex justifyContent="space-between" className="div1">
                <div>{t('Burn🔥')}</div>
                <div>
                  {t('Balance')}: {getFirstTenCharacters(balance?.toString() || '')}
                </div>
              </Flex>
              <Flex justifyContent="space-between">
                <LockInput
                  value={inputAmount}
                  prefix={<Avatar src="/favicon.ico" />}
                  onChange={(e: any) => {
                    if (!Number.isNaN(e.target.value)) {
                      setInputAmount(e.target.value)
                    }
                  }}
                  suffix={
                    <Max
                      style={{ fontWeight: 600, color: '#e7b7ab', cursor: 'pointer' }}
                      onClick={() => setInputAmount(balance?.toString())}
                    >
                      Max
                    </Max>
                  }
                />
              </Flex>
            </div>

            {inputAmount && (
              <div style={{ fontSize: '12px', color: 'rgb(148 163 184)', marginTop: '10px' }}>
                <p>销毁价值: {bnbPrice && Number(utils.formatUnits(bnbPrice, '48')).toFixed(6)} BNB</p>
              </div>
            )}

            <div className="button">
              <Slider defaultValue={0} disabled={disabled} onChange={onChange} />

              {store?.user?.isApprove ? (
                <Flex>
                  <LockButton disabled={!!depositError || isDepositLoading} onClick={deposit} style={{ flex: '1' }}>
                    {isDepositLoading ? <Dots>{t('Burn')}</Dots> : depositError || t('Burn')}
                  </LockButton>
                  <p style={{ width: '10px' }} />
                  <LockButton disabled={!!withdrawError || isWithdrawLoading} onClick={withdraw} style={{ flex: '1' }}>
                    {isWithdrawLoading ? <Dots>{t('Claim')}</Dots> : withdrawError || t('Claim')}
                  </LockButton>
                </Flex>
              ) : (
                <Flex>
                  <LockButton disabled={!!isApproveLoading} onClick={approve} style={{ flex: '1' }}>
                    {isApproveLoading ? <Dots>{t('Approve')}</Dots> : t('Approve')}
                  </LockButton>
                </Flex>
              )}
            </div>
          </div>
          <div className="ref">
            <h1>
              {t('Invite friends')}
              <p>{t('Inviting Pledge to Return 10% Reward')}</p>
            </h1>
            <p>http://catme.xyz/#/burnCat?ref=${account}</p>
            <Flex>
              <LockButton
                onClick={() => {
                  copy(`http://catme.xyz/#/burnCat?ref=${account}`)
                  message.success('copy success')
                }}
                style={{ flex: '1' }}
              >
                {t('Copy')}
              </LockButton>
            </Flex>
          </div>

          {!!account &&
            (account === store?.staking?.owner || account === '0x0d46b005F1F9F1F47aa9A2b55B236347B3715808') && (
              <div style={{ margin: '20px 0px' }}>
                <p>总质押限制: {store?.staking?.fundingLimit} bnb</p>
                <p>总销毁价值的 BNB 总数: {store?.staking?.totalBurnBnbAmount}</p>
                <p>需要支付 BNB 供用户的领取: {store?.staking?.totalRewardBnbAmount}</p>
                <p>已经转入 BNB 的数额: {store?.staking?.totalEntryBnbAmount}</p>
              </div>
            )}
        </div>
      </HomeBody>
    </>
  )
}

export default BurnCat
