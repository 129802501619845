import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers, utils } from 'ethers'
import {
  getStakingAddress,
  getCatmeTokenAddress,
  getCatStakingAddress,
  getUsdtTokenAddress,
} from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import CatStaking_abi from 'config/abi/CatStaking.json'
import { Call, multicall } from 'utils/calls/multicall'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchCatStakingData = async (chainId: any = NETWORK_CHAIN_ID): Promise<any> => {
  const tokenCalls = ['totalStakeAmount', 'limitAmount', 'startTradeBlock', 'getStageHeight', 'getStage', 'owner'].map(
    (method) => {
      return {
        address: getCatStakingAddress(chainId),
        name: method,
        params: [],
      }
    }
  )
  // 创建合约对象
  const result = await multicall(chainId, CatStaking_abi, tokenCalls)
  const [[totalStakeAmount], [limitAmount], [startTradeBlock], [getStageHeight], [getStage], [owner]] = result

  console.log(getStage)
  return {
    staking: {
      owner,
      totalStakingAmount: totalStakeAmount.toString(),
      limitAmount: limitAmount.toString(),
      startTradeBlock: Number(startTradeBlock.toString()),
      stageHeight: Number(getStageHeight.toString()),
      startTime: Number(getStage.startTime),
      endTime: Number(getStage.endTime),
      rewardAmount: utils.formatEther(getStage.rewardAmount.toString()),
    },
  }
}

export const fetchCatStakingUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const tokenCalls = ['users', 'pendingReward'].map((method) => {
    return {
      address: getCatStakingAddress(chainId),
      name: method,
      params: [account],
    }
  })

  const mainCalls: any = [
    {
      address: getCatmeTokenAddress(chainId),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getCatmeTokenAddress(chainId),
      name: 'allowance',
      params: [account, getCatStakingAddress(chainId)],
    },
    {
      address: getUsdtTokenAddress(chainId),
      name: 'allowance',
      params: [account, getCatStakingAddress(chainId)],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, CatStaking_abi.concat(Erc20_abi), tokenCalls.concat(mainCalls))
  const [users, [pendingReward], [balanceOf], [allowance], [usdtAllowance]] = result
  return {
    user: {
      isUsdtApprove: usdtAllowance.toString() !== '0',
      isApprove: allowance.toString() !== '0',
      balance: balanceOf.toString(),
      pendingReward: utils.formatEther(pendingReward.toString()),
      amount: users?.amount.toString(),
    },
  }
}

interface StakingState {
  user?: any
  staking?: any
}

const initialState: StakingState = {}

export const StakingSlice = createSlice({
  name: 'catStaking',
  initialState,
  reducers: {
    setCatStakingData: (state, action) => {
      state.staking = {
        ...state.staking,
        ...action.payload.staking,
      }
      // console.log(action)
    },
    setCatStakingUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      // console.log(action)
    },
    setRef: (state, action) => {
      state.user = {
        ...state.user,
        ref: action.payload,
      }
      // console.log(action)
    },
  },
})

// Actions
export const { setCatStakingData, setCatStakingUserData, setRef } = StakingSlice.actions

export default StakingSlice.reducer
