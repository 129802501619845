import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ethers, utils } from 'ethers'
import { getStakingAddress, getCatmeTokenAddress } from 'utils/addressHelpers'
import Erc20_abi from 'config/abi/Erc20.json'
import Staking_abi from 'config/abi/Staking.json'
import { Call, multicall } from 'utils/calls/multicall'
import { NETWORK_CHAIN_ID } from 'connectors'

export const fetchStakingData = async (chainId: any = NETWORK_CHAIN_ID): Promise<any> => {
  const tokenCalls = [
    'GlobalUsers',
    'TotalStakingAmount',
    'TotalMultiplierStakingAmount',
    'TotalReward',
    'maxProfitMultiplier',
    'limitAmount',
    'startTradeBlock',
    'limitTick',
  ].map((method) => {
    return {
      address: getStakingAddress(chainId),
      name: method,
      params: [],
    }
  })
  // 创建合约对象
  const result = await multicall(chainId, Staking_abi, tokenCalls)
  const [
    [GlobalUsers],
    [TotalStakingAmount],
    [TotalMultiplierStakingAmount],
    [TotalReward],
    [maxProfitMultiplier],
    [limitAmount],
    [startTradeBlock],
    [limitTick],
  ] = result

  console.log(limitAmount)
  return {
    staking: {
      globalUsers: Number(GlobalUsers.toString()),
      totalStakingAmount: TotalStakingAmount.toString(),
      totalMultiplierStakingAmount: TotalMultiplierStakingAmount.toString(),
      totalReward: TotalReward.toString(),
      maxProfitMultiplier: Number(maxProfitMultiplier.toString()) / 100,
      limitAmount: limitAmount.toString(),
      startTradeBlock: Number(startTradeBlock.toString()),
      limitTick: Number(limitTick.toString()),
    },
  }
}

export const fetchStakingUserData = async (chainId: any = NETWORK_CHAIN_ID, account: string): Promise<any> => {
  const tokenCalls = ['getTickCounts', 'pendingReward', 'userInfos', 'refs', 'refAmounts', 'refRewards'].map(
    (method) => {
      return {
        address: getStakingAddress(chainId),
        name: method,
        params: [account],
      }
    }
  )

  const mainCalls: any = [
    {
      address: getCatmeTokenAddress(chainId),
      name: 'balanceOf',
      params: [account],
    },
    {
      address: getCatmeTokenAddress(chainId),
      name: 'allowance',
      params: [account, getStakingAddress(chainId)],
    },
  ]

  // 创建合约对象
  const result = await multicall(chainId, Staking_abi.concat(Erc20_abi), tokenCalls.concat(mainCalls))
  const [
    [getTickCounts],
    [pendingReward],
    userInfos,
    [refs],
    [refAmounts],
    [refRewards],
    [balanceOf],
    [allowance],
  ] = result
  return {
    user: {
      refs: refs.toString() === ethers.constants.AddressZero ? undefined : refs.toString(),
      isApprove: allowance.toString() !== '0',
      balance: balanceOf.toString(),
      tickCounts: Number(getTickCounts.toString()),
      pendingReward: pendingReward.toString(),
      amount: userInfos?.amount.toString(),
      freezeAmount: userInfos?.freezeAmount.toString(),
      claimAmount: userInfos?.claimReward.toString(),
    },
  }
}

interface StakingState {
  user?: any
  staking?: any
}

const initialState: StakingState = {}

export const StakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setStakingData: (state, action) => {
      state.staking = {
        ...state.staking,
        ...action.payload.staking,
      }
      // console.log(action)
    },
    setStakingUserData: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.user,
      }
      // console.log(action)
    },
    setRef: (state, action) => {
      state.user = {
        ...state.user,
        ref: action.payload,
      }
      // console.log(action)
    },
  },
})

// Actions
export const { setStakingData, setStakingUserData, setRef } = StakingSlice.actions

export default StakingSlice.reducer
