import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch, useAppSelector } from 'state'
import styled from 'styled-components'
import { Flex, useMatchBreakpoints } from 'pancake-uikit'
import { Avatar, Button, Input, Slider, message } from 'antd'
import { fetchStakingData, fetchStakingUserData, setRef, setStakingData, setStakingUserData } from 'state/staking'
import { ethers, utils } from 'ethers'
import Dots from 'components/Loader/Dots'
import copy from 'copy-to-clipboard'
import { getStakingAddress } from 'utils/addressHelpers'
import { CurrencyAmount, WETH } from 'pancake-sdk'
import useI18nText from 'hooks/useI18n'
import { useDeposit, useWithdraw, usetCatmeApprove } from './hook'

const HomeBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  .flex-top {
    @media screen and (min-width: 360px) {
      height: 50px;
      width: 100%;
    }
    @media screen and (min-width: 576px) {
      height: 0px;
      width: 100%;
    }
  }
  .flex {
    height: 155px;
    width: 100%;
    /* display: flex; */

    display: flex;
    @media screen and (min-width: 360px) {
      position: none;
      bottom: 20px;
      height: 40px;

      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      position: fixed;
      bottom: 0px;
      height: 155px;
      margin-top: 0px;
    }
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-left: 10px;
      @media screen and (min-width: 360px) {
        width: 96px;
        /* height: 100vh; */
      }
      @media screen and (min-width: 576px) {
        width: 150px;
      }
    }
  }
  .body {
    height: 470px;
    border-radius: 20px;
    /* background-color: #fff; */
    > div:nth-child(1) {
      background-color: #fff;
      border-radius: 20px;
      padding: 10px 0px 40px 0px;
    }
    margin-top: 40px;

    @media screen and (min-width: 360px) {
      width: 100%;
      padding: 0px 0px;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }

    .title {
      p {
        color: rgb(75 85 99 / var(1));
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        margin: 20px 0px 5px 0px;
      }
      span {
        font-size: 1rem;
        line-height: 1;
        color: rgb(31 41 55 / 1);
        font-weight: 600;
      }
    }

    .data {
      display: flex;
      justify-content: center;
      padding: 20px 0px;
      > div {
        padding: 0px 10px;
      }
      p {
        color: rgb(107 114 128);
        font-weight: 600;
        font-size: 0.85rem;
        line-height: 1rem;
        margin: 10px 0px 5px 0px;
      }
      span {
        font-size: 0.75rem;
        line-height: 1;
        color: rgb(107 114 128);
        font-weight: 600;
      }
    }

    .input {
      border-radius: 5px;
      padding: 10px;
      border: 1px solid #e5e7eb;
      margin: 20px 20px 0px 20px;
      .div1 {
        div {
          color: rgb(148 163 184);
          font-size: 12px;
          line-height: 1.25rem;
        }
      }
    }

    .button {
      margin: 40px 20px 0px 20px;

      .ant-slider-track {
        background-color: rgb(255, 218, 217);
      }
      .ant-slider-handle::after {
        box-shadow: 0 0 0 2px #e7b7ab;
      }

      .ant-btn {
        background: '#fff';
      }
    }
  }

  .zhu {
    padding: 20px;
    @media screen and (min-width: 360px) {
      width: 100%;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }
    font-size: 15px;
  }
  .ref {
    border-radius: 20px;
    background-color: #fff;
    margin-top: 40px;
    margin-top: 20px;
    padding: 20px;
    @media screen and (min-width: 360px) {
      width: 100%;
      margin-top: 20px;
      /* height: 100vh; */
    }
    @media screen and (min-width: 576px) {
      width: 480px;
    }

    p {
      font-size: 0.75rem;
      line-height: 1;
      color: rgb(107 114 128);
      font-weight: 600;
      margin: 5px 0px;
      text-align: left;
    }
    h1 {
      text-align: left;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
`
const Max = styled.div``

const LockInput = styled(Input)`
  height: 60px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 4px 10px;
  > input {
    background-color: #f3f3f3;
    &:focus,
    &:focus-within,
    &:hover {
      background-color: #f3f3f3;
    }

    &:focus {
      box-shadow: none;
    }
  }
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #f3f3f3;
  }

  &:focus {
    box-shadow: none;
  }
`

const LockButton = styled(Button)`
  border: 1px solid #ffdad9;
  /* background-color: '#ffdad9' !important; */
  background-color: rgb(231, 183, 171);
  &:hover {
    color: #d39c63 !important;
    border: 1px solid #e7b7ab !important;
  }
`

/* eslint-disable */
function getFirstTenCharacters(str) {
  if (str.length >= 10) {
    return str.substring(0, 10)
  } else {
    return '0.0'
  }
}

const Home: React.FC<any> = () => {
  const t = useI18nText()

  const url = window.location.href

  useEffect(() => {
    const refs = url.split('ref=')
    if (refs.length > 1) {
      const ref = refs[1]
      console.log(`utils.isAddress(ref): ${utils.isAddress(ref)}`)
      if (utils.isAddress(ref)) {
        dispatch(setRef(ref))
      }
    }
  }, [url])

  const { chainId, account } = useWeb3React()
  const dispatch = useAppDispatch()
  const [inputAmount, setInputAmount] = useState('')
  const store = useAppSelector((state) => state.staking)

  const _amount = inputAmount?.slice(-1) === '.' ? inputAmount?.slice(0, -1) : inputAmount

  const limitAmount = useMemo(() => ethers.BigNumber.from(store.staking?.limitAmount || '0'), [store])
  const balance = useMemo(() => ethers.BigNumber.from(store.user?.balance || '0'), [store])
  const totalStakingAmount = useMemo(() => ethers.BigNumber.from(store.staking?.totalStakingAmount || '0'), [store])
  const amount = useMemo(() => ethers.BigNumber.from(store.user?.amount || '0'), [store])
  const pendingReward = useMemo(() => ethers.BigNumber.from(store.user?.pendingReward || '0'), [store])

  const freezeAmount = useMemo(
    () =>
      ethers.BigNumber.from(store.user?.freezeAmount || '0')
        .sub(store.user?.claimAmount || '0')
        .sub(pendingReward),
    [store, pendingReward]
  )

  const currencyBalance = WETH[chainId] && CurrencyAmount.fromRawAmount(WETH[chainId], balance.toString())
  const currencyTotalStakingAmount =
    WETH[chainId] && CurrencyAmount.fromRawAmount(WETH[chainId], totalStakingAmount.toString())
  const currencyAmount = WETH[chainId] && CurrencyAmount.fromRawAmount(WETH[chainId], amount.toString())
  const currencyFreezeAmount = WETH[chainId] && CurrencyAmount.fromRawAmount(WETH[chainId], freezeAmount.toString())
  const currencyPendingReward = WETH[chainId] && CurrencyAmount.fromRawAmount(WETH[chainId], pendingReward.toString())

  console.log(store)
  // useEffect(() => {
  //   fetchStakingData(chainId).then((data) => dispatch(setStakingData(data)))
  //   const initAny = async () => {
  //     if (!account) return
  //     const userData = await fetchStakingUserData(chainId, account)
  //     dispatch(setStakingUserData(userData))
  //   }
  //   initAny()
  // }, [account, dispatch, chainId])

  useEffect(() => {
    const fetchDataAndUserData = async () => {
      fetchStakingData(chainId).then((data) => dispatch(setStakingData(data)))

      if (account) {
        const userData = await fetchStakingUserData(chainId, account)
        dispatch(setStakingUserData(userData))
      }
    }

    // 执行一次初始逻辑
    fetchDataAndUserData()

    // 设置定时器，每隔一段时间执行一次逻辑
    const intervalId = setInterval(fetchDataAndUserData, 3000) // 每分钟执行一次

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId)
  }, [account, dispatch, chainId])

  const [disabled, setDisabled] = useState(false)

  const onChange = useMemo(() => {
    return (checked) => {
      setInputAmount(balance.mul(checked).div('100').toString())
    }
  }, [balance])

  const isClose = true
  /* eslint-disable */
  const depositError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0 || isClose) return t('Stake Clone')
    else if (inputAmount === '') return ''
    else if (store?.user?.tickCounts > store?.staking?.limitTick) return t('Limit Tick')
    else if (limitAmount.gt(inputAmount || '0')) return t('Limit Amount')
    else if (balance.lt(_amount)) return t('Insufficient Balance')
    else return ''
  }, [store, balance, _amount, inputAmount, isClose])

  /* eslint-disable */
  const withdrawError = useMemo(() => {
    if (store.staking?.startTradeBlock === 0) return t('Stake Clone')
    else if (amount.eq('0')) return t('Not Stake')
    else if (pendingReward.eq('0')) return t('Not Reward')
    else return ''
  }, [store, balance, _amount, inputAmount])

  const { isLoading: isApproveLoading, approve } = usetCatmeApprove(getStakingAddress(chainId))
  const { isLoading: isDepositLoading, deposit } = useDeposit({ inputAmount, ref: store?.user?.ref })
  const { isLoading: isWithdrawLoading, withdraw } = useWithdraw()

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <>
      <HomeBody>
        <div className="body">
          <div>
            <div className="zhu">
              <span>{t('Due to the large circulation, the pledge only displays the first 10 digits')}</span>
            </div>
            <div className="title">
              <p>{t('Total Stake')}</p>
              <span>{getFirstTenCharacters(totalStakingAmount?.toString() || '')}</span>
            </div>

            <div className="data">
              <Flex flexDirection="column">
                <p>{t('My Stake')}</p>
                <span>{getFirstTenCharacters(amount?.toString() || '')}</span>
              </Flex>
              <p style={{ margin: '0px 10px' }} />
              <Flex flexDirection="column">
                <p>{t('Release quantity')}</p>
                <span>{getFirstTenCharacters(freezeAmount.gt('500') ? freezeAmount?.toString() : '' || '')}</span>
              </Flex>

              <p style={{ margin: '0px 10px', height: '100%', width: '1px', background: 'rgb(229 231 235)' }} />
              <Flex flexDirection="column">
                <p>{t('My Reward')}</p>
                <span>{getFirstTenCharacters(pendingReward?.toString() || '')}</span>
              </Flex>
            </div>

            <div className="input">
              <Flex justifyContent="space-between" className="div1">
                <div>{t('Stake')}</div>
                <div>
                  {t('Balance')}: {getFirstTenCharacters(balance?.toString() || '')}
                </div>
              </Flex>
              <Flex justifyContent="space-between">
                <LockInput
                  value={inputAmount}
                  prefix={<Avatar src="/favicon.ico" />}
                  onChange={(e: any) => {
                    if (!Number.isNaN(e.target.value)) {
                      setInputAmount(e.target.value)
                    }
                  }}
                  suffix={
                    <Max
                      style={{ fontWeight: 600, color: '#e7b7ab', cursor: 'pointer' }}
                      onClick={() => setInputAmount(balance?.toString())}
                    >
                      Max
                    </Max>
                  }
                />
              </Flex>
            </div>
            <div className="button">
              <Slider defaultValue={0} disabled={disabled} onChange={onChange} />

              {store?.user?.isApprove ? (
                <Flex>
                  <LockButton disabled={!!depositError || isDepositLoading} onClick={deposit} style={{ flex: '1' }}>
                    {isDepositLoading ? <Dots>{t('Stake')}</Dots> : depositError || t('Stake')}
                  </LockButton>
                  <p style={{ width: '10px' }} />
                  <LockButton disabled={!!withdrawError || isWithdrawLoading} onClick={withdraw} style={{ flex: '1' }}>
                    {isWithdrawLoading ? <Dots>{t('Claim')}</Dots> : withdrawError || t('Claim')}
                  </LockButton>
                </Flex>
              ) : (
                <Flex>
                  <LockButton disabled={!!isApproveLoading} onClick={approve} style={{ flex: '1' }}>
                    {isApproveLoading ? <Dots>{t('Approve')}</Dots> : t('Approve')}
                  </LockButton>
                </Flex>
              )}
            </div>
          </div>
          <div className="ref">
            <h1>
              {t('Invite friends')}
              <p>{t('Inviting Pledge to Return 10% Reward')}</p>
            </h1>
            <p>http://catme.xyz/#/?ref=${account}</p>
            <Flex>
              <LockButton
                onClick={() => {
                  copy(`http://catme.xyz/#/?ref=${account}`)
                  message.success('copy success')
                }}
                style={{ flex: '1' }}
              >
                {t('Copy')}
              </LockButton>
            </Flex>
          </div>
          <div className="flex-top" />
          {isMobile && (
            <>
              <div className="flex">
                <div>
                  <a
                    href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xe57f73eb27da9d17f90c994744d842e95700c100&chain=bsc"
                    target="_blank"
                  >
                    <img src="images/1.png" alt="" />
                  </a>
                  <a
                    href="https://www.geckoterminal.com/bsc/pools/0x0654c33fb3021eeabf2725bdc1533faf02dc9743?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults"
                    target="_blank"
                  >
                    <img src="images/2.png" alt="" />
                  </a>
                  <a
                    href="https://www.dextools.io/app/en/bnb/pair-explorer/0x0654c33fb3021eeabf2725bdc1533faf02dc9743?t=1712489882877"
                    target="_blank"
                  >
                    <img src="images/3.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="flex">
                <div>
                  <a
                    href="https://coinmarketcap.com/dexscan/zh/bsc/0x0654c33fb3021eeabf2725bdc1533faf02dc9743/"
                    target="_blank"
                  >
                    <img src="images/4.png" alt="" />
                  </a>
                  <a href="https://www.dexview.com/bsc/0xB71b42f7F0513b3f49a0A42FfDcf90509A888888" target="_blank">
                    <img src="images/5.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="flex">
                <div>
                  <a href="https://bscscan.com/address/0xb71b42f7f0513b3f49a0a42ffdcf90509a888888" target="_blank">
                    <img src="images/6.png" alt="" />
                  </a>
                  <a href="https://poocoin.app/tokens/0xb71b42f7f0513b3f49a0a42ffdcf90509a888888" target="_blank">
                    <img src="images/7.png" alt="" />
                  </a>
                  <a style={{ width: '28px' }} href="ww.feixiaohao.com" target="_blank">
                    <img src="images/8.png" alt="" />
                  </a>
                </div>
              </div>
            </>
          )}
          <div className="flex-top" />
        </div>

        {!isMobile && (
          <div className="flex">
            <div>
              <a
                href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xe57f73eb27da9d17f90c994744d842e95700c100&chain=bsc"
                target="_blank"
              >
                <img src="images/1.png" alt="" />
              </a>
              <a
                href="https://www.geckoterminal.com/bsc/pools/0x0654c33fb3021eeabf2725bdc1533faf02dc9743?utm_source=coingecko&utm_medium=referral&utm_campaign=searchresults"
                target="_blank"
              >
                <img src="images/2.png" alt="" />
              </a>
              <a
                href="https://www.dextools.io/app/en/bnb/pair-explorer/0x0654c33fb3021eeabf2725bdc1533faf02dc9743?t=1712489882877"
                target="_blank"
              >
                <img src="images/3.png" alt="" />
              </a>
              <a
                href="https://coinmarketcap.com/dexscan/zh/bsc/0x0654c33fb3021eeabf2725bdc1533faf02dc9743/"
                target="_blank"
              >
                <img src="images/4.png" alt="" />
              </a>
              <a href="https://www.dexview.com/bsc/0xB71b42f7F0513b3f49a0A42FfDcf90509A888888" target="_blank">
                <img src="images/5.png" alt="" />
              </a>
              <a href="https://bscscan.com/address/0xb71b42f7f0513b3f49a0a42ffdcf90509a888888" target="_blank">
                <img src="images/6.png" alt="" />
              </a>
              <a href="https://poocoin.app/tokens/0xb71b42f7f0513b3f49a0a42ffdcf90509a888888" target="_blank">
                <img src="images/7.png" alt="" />
              </a>
              <a style={{ width: '30px' }} href="ww.feixiaohao.com" target="_blank">
                <img src="images/8.png" alt="" />
              </a>
            </div>
          </div>
        )}
      </HomeBody>
    </>
  )
}

export default Home
